// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Button,
//   Typography,
//   ThemeProvider,
//   createTheme,
//   Paper,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   TextField,
//   Grid,
//   IconButton,
// } from "@mui/material";
// import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
// import EditIcon from "@mui/icons-material/Edit";
// import AddCategory from "./AddCategory";
// import { getAllCategories } from "./API/AllApi";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#2196f3",
//     },
//     secondary: {
//       main: "#ffffff",
//     },
//     background: {
//       default: "#f5f5f5",
//     },
//   },
// });

// const Category = () => {

//   const navigate = useNavigate(); // Get the navigate function from the hook

//   const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
//     useState(false);
//   const [openDeleteSubcategoryDialog, setOpenDeleteSubcategoryDialog] =
//     useState(false);
//   const [openInfoDialog, setOpenInfoDialog] = useState(false);
//   const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
//     useState(false);
//   const [openThumbnailDialog, setOpenThumbnailDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [filteredCategory, setFilteredCategory] = useState("");
//   const [newSubCategoryName, setNewSubCategoryName] = useState("");
//   const [selectedSubCategoryCategory, setSelectedSubCategoryCategory] =
//     useState("");
//   const [categories, setCategories] = useState([]);
//   const [subCategoriesToShow, setSubCategoriesToShow] = useState([]);
//   const [selectedImageFile, setSelectedImageFile] = useState(null);
//   const [categoryName, setCategoryName] = useState("");
//   const [categoryType, setCategoryType] = useState("");
//   const [subCategories, setSubCategories] = useState([]);
//   const [error, setError] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [subcategoryToDelete, setSubcategoryToDelete] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
//   const [newSubcategoryName, setNewSubcategoryName] = useState("");
//   const [loading, setLoading] = useState(true);
//   // Fetch categories from API
//   useEffect(() => {
//     const fetchCategories = async () => {
//       setLoading(true);
//       try {
//         const data = await getAllCategories();
//         const formattedCategories = data.map((cat) => ({
//           id: cat.id,
//           name: cat.categoryName,
//           type: cat.type,
//           image: cat.thumbnail,
//           subCategories: cat.subcategories,
//         }));
//         setCategories(formattedCategories);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//         setError(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCategories();
//   }, []);

//   const handleUpdateThumbnail = (category) => {
//     setSelectedCategory(category);
//     setOpenThumbnailDialog(true);
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const updatedCategories = categories.map((cat) => {
//           if (cat.id === selectedCategory.id) {
//             return { ...cat, image: reader.result }; // Update the image preview
//           }
//           return cat;
//         });
//         setCategories(updatedCategories);
//         setSelectedImageFile(file);
//         setOpenThumbnailDialog(false);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleDeleteCategoryConfirmation = (category) => {
//     setSelectedCategory(category);
//     setOpenDeleteCategoryDialog(true);
//   };

//   const handleDeleteCategory = async () => {
//     try {
//       await axios.delete(
//         `https://mahastudy.in:8443/deleteCategory/${selectedCategory.id}`
//       ); // DELETE API call
//       const updatedCategories = categories.filter(
//         (category) => category.id !== selectedCategory.id
//       );
//       setCategories(updatedCategories);
//       handleDialogClose();
//     } catch (error) {
//       console.error("Error deleting category:", error);
//     }
//   };

//   const handleDeleteSubCategory = (subCategory) => {
//     setSubcategoryToDelete(subCategory);
//     setOpenDeleteSubcategoryDialog(true); // Open the confirmation dialog
//   };

//   const handleDeleteSubCategoryConfirm = async () => {
//     try {
//       await axios.delete(
//         `https://mahastudy.in:8443/deleteSubcategory/${subcategoryToDelete.id}`
//       );
//       const updatedSubCategories = subCategories.filter(
//         (sc) => sc.id !== subcategoryToDelete.id
//       );
//       setSubCategories(updatedSubCategories);
//       setOpenDeleteSubcategoryDialog(false);
//       const updatedCategories = categories.map((category) => {
//         if (category.id === selectedCategory.id) {
//           return { ...category, subCategories: updatedSubCategories };
//         }
//         return category;
//       });
//       setCategories(updatedCategories);
//     } catch (error) {
//       console.error("Error deleting sub-category:", error);
//     }
//   };

//   const handleDialogClose = () => {
//     setOpenDeleteCategoryDialog(false);
//     setOpenInfoDialog(false);
//     setOpenAddSubCategoryDialog(false);
//     setOpenThumbnailDialog(false);
//     setNewSubCategoryName("");
//     setSelectedSubCategoryCategory("");
//     setSelectedImageFile(null);
//     setOpenDeleteSubcategoryDialog(false); // Close subcategory delete dialog
//     setOpenEditDialog(false);
//   };

//   const handleInfoOpen = (category) => {
//     setSelectedCategory(category);
//     setCategoryName(category.name);
//     setCategoryType(category.type);
//     setOpenInfoDialog(true);
//   };

//   const handleAddSubCategoryOpen = (category) => {
//     setSelectedCategory(category);
//     setOpenAddSubCategoryDialog(true);
//   };

//   const handleAddSubCategory = async () => {
//     if (selectedSubCategoryCategory && newSubCategoryName) {
//       try {
//         const selectedCategoryObj = categories.find(
//           (cat) => cat.id === selectedCategory.id
//         );
//         if (!selectedCategoryObj) {
//           console.error("Selected category not found.");
//           return;
//         }

//         const response = await axios.post(
        //   "https://mahastudy.in:8443/addsubcategory",
        //   {
        //     subcategoryName: newSubCategoryName,
        //     categoryId: selectedCategoryObj.id,
        //   }
        // );

//         const newSubCategory = response.data;
//         if (!newSubCategory) {
//           console.error("No sub-category returned from the API.");
//           return;
//         }

//         const updatedCategories = categories.map((category) => {
//           if (category.id === selectedCategoryObj.id) {
//             return {
//               ...category,
//               subCategories: [...category.subCategories, newSubCategory],
//             };
//           }
//           return category;
//         });
//         setCategories(updatedCategories);
//         handleDialogClose();
//       } catch (error) {
//         console.error("Error adding sub-category:", error);
//       }
//     } else {
//       console.error("Category or Sub-category name is missing.");
//     }
//   };
//   const handleShowSubCategories = async (category) => {
//     try {
//       const response = await axios.get(
//         `https://mahastudy.in:8443/subcategoryByCategory`,
//         {
//           params: {
//             categoryName: category.name, // Get subcategories by passing category name
//           },
//         }
//       );

//       // Assuming response.data contains the specific subcategories
//       const subCategories = response.data;

//       navigate("/Layout/viewSubcategory", {
//         state: {
//           categoryId: category.id,
//           categoryName: category.name,
//           subCategories: subCategories,
//         }, // Pass the data
//       });
//     } catch (err) {
//       setError("Error fetching sub-categories");
//       console.error("Fetch subcategories error:", err);
//     }
//   };

//   const handleEditSubCategory = (subCategory) => {
//     setSubcategoryToEdit(subCategory);
//     setNewSubcategoryName(subCategory.subcategoryName);
//     setOpenEditDialog(true); // Open the edit dialog
//   };

//   const handleUpdateSubCategory = async () => {
//     if (!newSubcategoryName || !subcategoryToEdit || !selectedCategory) {
//       console.error("Required fields are missing.");
//       return;
//     }

//     console.log("Subcategory to edit:", subcategoryToEdit); // Added log

//     try {
//       const response = await axios.put(
//         `https://mahastudy.in:8443/updateSubCategory/${subcategoryToEdit.id}`,
//         {
//           subcategoryName: newSubcategoryName,
//           categoryId: selectedCategory.id, // Include the category ID in the request body
//         }
//       );
//       // Process the response...
//       console.log("Response from server:", response); // Log response
//     } catch (error) {
//       console.error("Error updating subcategory:", error);
//     }
//   };

//   const handleUpdateCategory = async () => {
//     const formData = new FormData();
//     formData.append("name", categoryName);
//     formData.append("type", categoryType);
//     if (selectedImageFile) {
//       formData.append("thumbnail", selectedImageFile);
//     }

//     try {
//       await axios.put(
//         `https://mahastudy.in:8443/updateCategory/${selectedCategory.id}`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );
//       const updatedCategories = categories.map((category) => {
//         if (category.id === selectedCategory.id) {
//           return { ...category, name: categoryName, type: categoryType };
//         }
//         return category;
//       });
//       setCategories(updatedCategories);
//       handleDialogClose();
//     } catch (error) {
//       console.error("Error updating category:", error);
//     }
//   };

//   const filteredCategories = filteredCategory
//     ? categories.filter((category) => category.name === filteredCategory)
//     : categories;

//   const handleClose = () => {
//     setOpen(false);
//     setSubCategories([]);
//     setError(null);
//   };
//   // Function to handle the newly added category
//   const handleCategoryAdded = (newCategory) => {
//     setCategories((prevCategories) => [...prevCategories, newCategory]);
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <div style={{ padding: "20px" }}>
//         <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
//           <Grid container spacing={2} alignItems="center" wrap="nowrap">
//             <Grid item xs={12} md={4}>
//               <Typography variant="body1" align="center" sx={{ color: "grey" }}>
//                 {`Total Categories: ${categories.length}`}
//               </Typography>
//             </Grid>
//             {/* Add Category Button */}
//             <Grid item xs={12} md={4}>
//               <AddCategory onCategoryAdded={handleCategoryAdded} />
//             </Grid>

//             {/* Select Category Dropdown */}
//             <Grid item xs={12} md={4}>
//               <FormControl variant="outlined" fullWidth>
//                 <InputLabel id="category-select-label">
//                   Select Category
//                 </InputLabel>
//                 <Select
//                   labelId="category-select-label"
//                   value={filteredCategory}
//                   onChange={(e) => setFilteredCategory(e.target.value)}
//                   label="Select Category"
//                 >
//                   <MenuItem value="">
//                     <em>All</em>
//                   </MenuItem>
//                   {categories.map((category) => (
//                     <MenuItem key={category.id} value={category.name}>
//                       {category.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>

//             {/* Total Categories Count */}
//           </Grid>
//         </Paper>

//         <TableContainer>
//           <Table className="table-root">
//             <TableHead>
//               <TableRow>
//                 <TableCell>ID</TableCell>
//                 <TableCell>Category Name</TableCell>

//                 <TableCell>Sub-Categories</TableCell>

//                 <TableCell>Thumbnail</TableCell>
//                 <TableCell>Delete</TableCell>
//                 <TableCell>Update</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {filteredCategories.map((category) => (
//                 <TableRow key={category.id}>
//                   <TableCell>{category.id}</TableCell>
//                   <TableCell>{category.name}</TableCell>

//                   <TableCell>
//                     <Button
//                       variant="outlined"
//                       color="primary"
//                       onClick={() => handleShowSubCategories(category)}
//                     >
//                       View Sub-Categories
//                     </Button>
//                   </TableCell>

//                   <TableCell>
//                     {category.image ? (
//                       <img
//                         src={category.image}
//                         alt="Thumbnail"
//                         style={{ width: "100px", height: "auto" }}
//                       />
//                     ) : (
//                       "No Thumbnail"
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     <IconButton
//                       color="error"
//                       onClick={() => handleDeleteCategoryConfirmation(category)}
//                     >
//                       <DeleteRoundedIcon />
//                     </IconButton>
//                   </TableCell>

//                   <TableCell>
//                     <IconButton
//                       color="info" // Remove variant from IconButton, it's only for Button component
//                       onClick={() => handleInfoOpen(category)} // Properly close the IconButton opening tag
//                     >
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         {/* Info Dialog */}
//         <Dialog open={openInfoDialog} onClose={handleDialogClose}>
//           <DialogTitle>Category Information</DialogTitle>
//           <DialogContent>
//             <TextField
//               label="Category Name"
//               variant="outlined"
//               fullWidth
//               value={categoryName}
//               onChange={(e) => setCategoryName(e.target.value)}
//               style={{ marginTop: "20px" }}
//             />

//             <DialogTitle>Update Thumbnail</DialogTitle>
//             <DialogContent>
//               <input
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageChange}
//               />
//               {selectedImageFile && (
//                 <img
//                   src={URL.createObjectURL(selectedImageFile)}
//                   alt="Preview"
//                   style={{ width: "100px", height: "auto" }}
//                 />
//               )}
//             </DialogContent>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleUpdateCategory} color="primary">
//               Update
//             </Button>
//             <Button onClick={handleDialogClose} color="primary">
//               Cancel
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/* Add Sub-Category Dialog */}
//         <Dialog open={openAddSubCategoryDialog} onClose={handleDialogClose}>
//           <DialogTitle>Add Sub-Category</DialogTitle>
//           <DialogContent>
//             <FormControl fullWidth>
//               <InputLabel id="select-subcategory-label">
//                 Select Category
//               </InputLabel>
//               <Select
//                 labelId="select-subcategory-label"
//                 value={selectedSubCategoryCategory}
//                 onChange={(e) => setSelectedSubCategoryCategory(e.target.value)}
//                 label="Select Category"
//               >
//                 {categories.map((category) => (
//                   <MenuItem key={category.id} value={category.name}>
//                     {category.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <TextField
//               label="Sub-Category Name"
//               variant="outlined"
//               fullWidth
//               value={newSubCategoryName}
//               onChange={(e) => setNewSubCategoryName(e.target.value)}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleAddSubCategory} color="primary">
//               Add
//             </Button>
//             <Button onClick={handleDialogClose} color="primary">
//               Cancel
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/* Delete Category Confirmation Dialog */}
//         <Dialog open={openDeleteCategoryDialog} onClose={handleDialogClose}>
//           <DialogTitle>Confirm Deletion</DialogTitle>
//           <DialogContent>
//             <Typography>
//               Are you sure you want to delete this category?
//             </Typography>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleDeleteCategory} color="error">
//               Delete
//             </Button>
//             <Button onClick={handleDialogClose} color="primary">
//               Cancel
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/* Sub-Category Dialog */}
//         <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//           <DialogTitle
//             style={{
//               backgroundColor: "#3f51b5",
//               color: "#fff",
//               textAlign: "center",
//             }}
//           >
//             Sub-Categories
//           </DialogTitle>
//           <DialogContent style={{ padding: "20px" }}>
//             {error && (
//               <Typography color="error" style={{ marginBottom: "16px" }}>
//                 {error}
//               </Typography>
//             )}
//             <Grid container spacing={2}>
//               {subCategories.map((subCategory) => (
//                 <Grid item xs={12} sm={6} md={4} key={subCategory.id}>
//                   <div
//                     style={{
//                       border: "1px solid #ccc",
//                       borderRadius: "4px",
//                       padding: "16px",
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                       transition: "transform 0.2s",
//                     }}
//                   >
//                     <Typography variant="body1">{subCategory.id}</Typography>
//                     <Typography variant="body1">
//                       {subCategory.subcategoryName}
//                     </Typography>
//                     <div>
//                       <Button
//                         color="primary"
//                         onClick={() => handleEditSubCategory(subCategory)}
//                         style={{ marginRight: "8px" }}
//                       >
//                         Edit
//                       </Button>
//                       <Button
//                         color="error"
//                         onClick={() => handleDeleteSubCategory(subCategory)}
//                       >
//                         Delete
//                       </Button>
//                     </div>
//                   </div>
//                 </Grid>
//               ))}
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/* Edit Sub-Category Dialog */}
//         <Dialog open={openEditDialog} onClose={handleDialogClose}>
//           <DialogTitle>Edit Sub-Category</DialogTitle>
//           <DialogContent>
//             <TextField
//               label="New Sub-Category Name"
//               variant="outlined"
//               fullWidth
//               value={newSubcategoryName}
//               onChange={(e) => setNewSubcategoryName(e.target.value)}
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleUpdateSubCategory} color="primary">
//               Update
//             </Button>
//             <Button onClick={handleDialogClose} color="primary">
//               Cancel
//             </Button>
//           </DialogActions>
//         </Dialog>

//         {/* Delete Sub-Category Confirmation Dialog */}
//         <Dialog open={openDeleteSubcategoryDialog} onClose={handleDialogClose}>
//           <DialogTitle>Confirm Deletion</DialogTitle>
//           <DialogContent>
//             <Typography>
//               Are you sure you want to delete the sub-category "
//               {subcategoryToDelete?.subcategoryName}"?
//             </Typography>
//           </DialogContent>
//           <DialogActions>
//             <Button
//               onClick={() => setOpenDeleteSubcategoryDialog(false)}
//               color="primary"
//             >
//               Cancel
//             </Button>
//             <Button onClick={handleDeleteSubCategoryConfirm} color="error">
//               Delete
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </div>
//     </ThemeProvider>
//   );
// };

// export default Category;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  ThemeProvider,
  createTheme,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import AddCategory from "./AddCategory";
import { getAllCategories } from "./API/AllApi";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

const Category = () => {
  const navigate = useNavigate();
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] =
    useState(false);
  const [openDeleteSubcategoryDialog, setOpenDeleteSubcategoryDialog] =
    useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [openAddSubCategoryDialog, setOpenAddSubCategoryDialog] =
    useState(false);
  const [openThumbnailDialog, setOpenThumbnailDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  const [selectedSubCategoryCategory, setSelectedSubCategoryCategory] =
    useState("");
  const [categories, setCategories] = useState([]);
  const [subCategoriesToShow, setSubCategoriesToShow] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [subcategoryToDelete, setSubcategoryToDelete] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const data = await getAllCategories();
        const formattedCategories = data.map((cat) => ({
          id: cat.id,
          name: cat.categoryName,
          type: cat.type,
          image: cat.thumbnail,
          subCategories: cat.subcategories,
        }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteCategoryConfirmation = (category) => {
    setSelectedCategory(category);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteCategory();
      }
    });
  };

  // const handleDeleteCategory = async () => {
  //   try {
  //     await axios.delete(
  //       `https://mahastudy.in:8443/deleteCategory/${selectedCategory.id}`
  //     );
  //     const updatedCategories = categories.filter(
  //       (category) => category.id !== selectedCategory.id
  //     );
  //     setCategories(updatedCategories);
  //   } catch (error) {
  //     Swal.fire("Error", "Error deleting category", "error");
  //     console.error("Error deleting category:", error);
  //   }
  // };

  const handleDeleteCategory = (category) => {
    if (!category || !category.id) {
      console.error("Cannot delete category: category or category.id is null");
      console.log("Category object:", category);  // Log the category object for debugging
      return;
    }
  
    fetch(`https://mahastudy.in:8443/deleteCategory/${category.id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to delete category");
        }
        console.log("Category deleted successfully");
        // Further code for handling successful deletion
      })
      .catch(error => {
        console.error("Error deleting category:", error);
      });
  };
  
  const handleDeleteSubCategory = (subCategory) => {
    setSubcategoryToDelete(subCategory);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this sub-category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteSubCategoryConfirm();
      }
    });
  };

  const handleDeleteSubCategoryConfirm = async () => {
    try {
      await axios.delete(
        `https://mahastudy.in:8443/deleteSubcategory/${subcategoryToDelete.id}`
      );
      const updatedSubCategories = subCategories.filter(
        (sc) => sc.id !== subcategoryToDelete.id
      );
      setSubCategories(updatedSubCategories);
      const updatedCategories = categories.map((category) => {
        if (category.id === selectedCategory.id) {
          return { ...category, subCategories: updatedSubCategories };
        }
        return category;
      });
      setCategories(updatedCategories);
    } catch (error) {
      Swal.fire("Error", "Error deleting sub-category", "error");
      console.error("Error deleting sub-category:", error);
    }
  };

  const handleInfoOpen = (category) => {
    setSelectedCategory(category);
    setCategoryName(category.name);
    setCategoryType(category.type);
    setOpenInfoDialog(true);
  };

  const handleAddSubCategoryOpen = (category) => {
    setSelectedCategory(category);
    setOpenAddSubCategoryDialog(true);
  };

  const handleAddSubCategory = async () => {
    if (selectedSubCategoryCategory && newSubCategoryName) {
      try {
        const selectedCategoryObj = categories.find(
          (cat) => cat.id === selectedCategory.id
        );
        if (!selectedCategoryObj) {
          console.error("Selected category not found.");
          return;
        }

        const response = await axios.post(
          "https://mahastudy.in:8443/addsubcategory",
          {
            subcategoryName: newSubCategoryName,
            categoryId: selectedCategoryObj.id,
          }
        );

        const newSubCategory = response.data;
        if (!newSubCategory) {
          console.error("No sub-category returned from the API.");
          return;
        }

        const updatedCategories = categories.map((category) => {
          if (category.id === selectedCategoryObj.id) {
            return {
              ...category,
              subCategories: [...category.subCategories, newSubCategory],
            };
          }
          return category;
        });
        setCategories(updatedCategories);
        setOpenAddSubCategoryDialog(false);
      } catch (error) {
        Swal.fire("Error", "Error adding sub-category", "error");
        console.error("Error adding sub-category:", error);
      }
    } else {
      Swal.fire("Error", "Category or Sub-category name is missing", "error");
    }
  };
  const handleShowSubCategories = async (category) => {
    try {
      const response = await axios.get(
        `https://mahastudy.in:8443/subcategoryByCategory`,
        {
          params: {
            categoryName: category.name,
          },
        }
      );

      const subCategories = response.data;

      navigate("/Layout/viewSubcategory", {
        state: {
          categoryId: category.id,
          categoryName: category.name,
          subCategories: subCategories,
        },
      });
    } catch (err) {
      setError("Error fetching sub-categories");
      Swal.fire("Error", "Fetch subcategories error", "error");
      console.error("Fetch subcategories error:", err);
    }
  };

  const handleUpdateCategory = async () => {
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("type", categoryType);
    if (selectedImageFile) {
      formData.append("thumbnail", selectedImageFile);
    }

    try {
      await axios.put(
        `https://mahastudy.in:8443/updateCategory/${selectedCategory.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const updatedCategories = categories.map((category) => {
        if (category.id === selectedCategory.id) {
          return { ...category, name: categoryName, type: categoryType };
        }
        return category;
      });
      setCategories(updatedCategories);
      setOpenInfoDialog(false);
      Swal.fire("Success", "Category updated successfully", "success");
    } catch (error) {
      Swal.fire("Error", "Error updating category", "error");
      console.error("Error updating category:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "20px" }}>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item xs={12} md={4}>
              <Typography variant="body1" align="center" sx={{ color: "grey" }}>
                {`Total Categories: ${categories.length}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label="Filter Category"
                value={filteredCategory}
                onChange={(e) => setFilteredCategory(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
              <AddCategory />
            </Grid>
          </Grid>
        </Paper>

        <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category Name</TableCell>{" "}
                <TableCell>Thumbnail</TableCell>
                <TableCell>SubCategory</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories
                .filter((category) =>
                  category.name
                    .toLowerCase()
                    .includes(filteredCategory.toLowerCase())
                )
                .map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      {category.image ? (
                        <img
                          src={category.image}
                          alt={category.name}
                          style={{
                            width: "50px",
                            height: "50px",
                            objectFit: "cover",
                            borderRadius: "5px", // optional
                          }}
                        />
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No Image
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleShowSubCategories(category)}
                      >
                        View Subcategories
                      </Button>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        onClick={() => handleInfoOpen(category)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteCategoryConfirmation(category)
                        }
                        color="error"
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
};

export default Category;
