// import React, { useState } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import Dashboard from "./components/Dashboard";
// import Ebook from "./components/Ebook";
// import Purchase from "./components/Purchase";
// import Chapters from "./components/Chapters";
// import Sidebar from "./components/Sidebar";
// import "./App.css";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Add from "./components/Add";
// import Category from "./components/Category";
// import Signup from "./components/Signup";
// //import HomePage from './components/HomePage';
// import Report from "./components/Report";
// import AddData from "./components/AddData";
// import ViewSubCategories from "./components/ViewSubcategory";
// import Login from "./components/Login";
// import Layout from "./components/Layout";
// import AddMaterial from "./components/AddMaterial";
// import AddCategory from "./components/AddCategory";
// import SalesList from "./components/SalesList";
// import CategoryList from "./components/CategoryList";
// import SubcategoryList from "./components/SubcategoryList";
// const theme = createTheme({
//   typography: {
//     fontFamily: '"Poppins", sans-serif', // Changed to Poppins
//   },
// });
// function App() {
//   const [mobileOpen, setMobileOpen] = useState(false);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   return (
//     <div className="App">
//       <ThemeProvider theme={theme}>
//         {/* <ThemeProvider theme={theme}> */}
//         {/* Ensure that everything is inside the Router */}
//         <Router>
//           {/* Conditionally render Navbar and Sidebar */}
//           {window.location.pathname !== "" ||
//             "/" ||
//             ("/admin" && (
//               <>
//                 <Navbar handleDrawerToggle={handleDrawerToggle} />
//                 <Sidebar
//                   mobileOpen={mobileOpen}
//                   handleDrawerToggle={handleDrawerToggle}
//                 />
//               </>
//             ))}
//           <div className="content">
//             <Routes basename="/admin">
//               <Route path="/admin" element={<Login />} />
//               <Route path="/ebooksignup" element={<Signup />} />
//               <Route path="/ebooklayout/" element={<Layout />}>
//                 {/* <Route path="home" element={<HomePage />} /> */}
//                 <Route path="ebookdashboard" element={<Dashboard />} />
//                 <Route path="ebook" element={<Ebook />} />
//                 <Route path="add" element={<Add />} />
//                 <Route path="chapters/:category" element={<Chapters />} />
//                 <Route path="category" element={<Category />} />
//                 <Route path="purchase" element={<Purchase />} />
//                 <Route path="SalesList" element={<SalesList />} />
//                 <Route
//                   path="addCategory/:materialTypeId"
//                   element={<CategoryList />}
//                 />
//                 <Route
//                   path="addSubCategory/:id"
//                   element={<SubcategoryList />}
//                 />
//                 <Route path="report" element={<Report />} />
//                 <Route path="addmatrialdata" element={<AddData />} />
//                 <Route path="AddMaterial" element={<AddMaterial />} />
//                 <Route path="viewSubcategory" element={<ViewSubCategories />} />
//               </Route>
//             </Routes>
//           </div>
//         </Router>
//         {/* </ThemeProvider> */}
//       </ThemeProvider>
//     </div>
//   );
// }

// export default App;

import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Layout from "./components/Layout";
import LoginPage from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/Dashboard";
import Ebook from "./components/Ebook";
import Purchase from "./components/Purchase";
import Chapters from "./components/Chapters";
import Add from "./components/Add";
import Category from "./components/Category";
import Report from "./components/Report";
import AddData from "./components/AddData";
import ViewSubcategory from "./components/ViewSubcategory";
import AddMaterial from "./components/AddMaterial";
import AddCategory from "./components/AddCategory";
import SalesList from "./components/SalesList";
import CategoryList from "./components/CategoryList";
import SubcategoryList from "./components/SubcategoryList";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/admin" element={<LoginPage />} />
          <Route path="/ebooksignup" element={<Signup />} />
          <Route path="/ebooklayout" element={<Layout />}>
            <Route path="ebookdashboard" element={<Dashboard />} />
            <Route path="ebook" element={<Ebook />} />
            <Route path="purchase" element={<Purchase />} />
            <Route path="chapters/:category" element={<Chapters />} />
            <Route path="add" element={<Add />} />
            <Route path="category" element={<Category />} />
            <Route path="report" element={<Report />} />
            <Route path="addmatrialdata" element={<AddData />} />
            <Route path="viewSubcategory" element={<ViewSubcategory />} />
            <Route path="addmaterial" element={<AddMaterial />} />
            <Route path="addCategory/:materialTypeId" element={<CategoryList />} />
            <Route path="addSubCategory/:id" element={<SubcategoryList />} />
            <Route path="saleslist" element={<SalesList />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
