import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  getAllCategoriesByMaterialType,
  getSubcategoriesByCategory,
  updateMaterialById,
} from "./API/AllApi";

const UpdateMaterial = ({
  open,
  onClose,
  selectedMaterial,
  onUpdate,
  materialTypes,
}) => {
  const [localMaterial, setLocalMaterial] = useState(selectedMaterial || {});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // Fetch categories based on material type
  useEffect(() => {
    if (localMaterial.materialtype) {
      fetchCategories(localMaterial.materialtype);
    }
  }, [localMaterial.materialtype]);

  const fetchCategories = async (materialTypeName) => {
    try {
      const data = await getAllCategoriesByMaterialType(materialTypeName);
      setCategories(
        data.map((cat) => ({ id: cat.id, name: cat.categoryName }))
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (selectedMaterial) {
      setLocalMaterial(selectedMaterial);
      fetchSubcategories(selectedMaterial.categoryName);
    }
  }, [selectedMaterial]);

  const fetchSubcategories = async (categoryName) => {
    if (!categoryName) {
      setSubcategories([]);
      return;
    }
    try {
      const data = await getSubcategoriesByCategory(categoryName);
      setSubcategories(
        data.map((subCat) => ({ id: subCat.id, name: subCat.subcategoryName }))
      );

      if (selectedMaterial && selectedMaterial.subcategoryId) {
        const selectedSubcategory = data.find(
          (subCat) => subCat.id === selectedMaterial.subcategoryId
        );
        if (selectedSubcategory) {
          setLocalMaterial((prev) => ({
            ...prev,
            subcategoryId: selectedSubcategory.id,
            subcategoryName: selectedSubcategory.name,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalMaterial((prev) => ({ ...prev, [name]: value }));

    if (name === "categoryName") {
      setLocalMaterial((prev) => ({
        ...prev,
        subcategoryId: "",
        subcategoryName: "",
      }));
      fetchSubcategories(value);
    }

    // If status is free, set MRP and Price to 0.0 and hide fields
    if (name === "status" && value === "free") {
      setLocalMaterial((prev) => ({
        ...prev,
        mrp: 0.0,
        price: 0.0,
      }));
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setLocalMaterial((prev) => ({ ...prev, [name]: files[0] }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setLocalMaterial((prev) => ({ ...prev, [name]: checked }));
  };

  const handleUpdate = async () => {
    try {
      if (!localMaterial.materialtype || !localMaterial.categoryName) {
        alert("Please fill in the required fields.");
        return;
      }
      await updateMaterialById(localMaterial.id, localMaterial);
      onUpdate(localMaterial);
      onClose();
    } catch (error) {
      console.error("Error updating material:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Material</DialogTitle>
      <DialogContent sx={{ marginTop: "10px" }}>
        <Grid container spacing={2} className="textField-root">
          <Grid item xs={6}>
            <TextField
              label="Material Type"
              value={localMaterial.materialtype || ""}
              select
              onChange={(e) =>
                handleInputChange({
                  target: { name: "materialtype", value: e.target.value },
                })
              }
              fullWidth
            >
              {materialTypes.map((material) => (
                <MenuItem key={material.id} value={material.materialtype}>
                  {material.materialtype}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Category"
              select
              value={localMaterial.categoryName || ""}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "categoryName", value: e.target.value },
                })
              }
              fullWidth
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Subcategory"
              select
              value={localMaterial.subcategoryName || ""}
              onChange={(e) => {
                const selectedSubcategoryName = e.target.value;
                const selectedSubcategory = subcategories.find(
                  (subCat) => subCat.name === selectedSubcategoryName
                );
                setLocalMaterial((prev) => ({
                  ...prev,
                  subcategoryId: selectedSubcategory
                    ? selectedSubcategory.id
                    : "",
                  subcategoryName: selectedSubcategoryName,
                }));
              }}
              fullWidth
              disabled={!localMaterial.categoryName}
            >
              {subcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.name}>
                  {subcategory.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Subject Name"
              value={localMaterial.chapterName || ""}
              name="chapterName"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Status"
              select
              value={localMaterial.status || ""}
              onChange={(e) =>
                handleInputChange({
                  target: { name: "status", value: e.target.value },
                })
              }
              fullWidth
            >
              <MenuItem value="free">Free</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </TextField>
          </Grid>
          {localMaterial.status !== "free" && (
            <>
              <Grid item xs={6}>
                <TextField
                  label="MRP"
                  value={localMaterial.mrp || ""}
                  name="mrp"
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Price"
                  value={localMaterial.price || ""}
                  name="price"
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </>
          )}

          <Grid item xs={6}>
            <TextField
              label="Validity (months)"
              value={localMaterial.validity || ""}
              name="validity"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={localMaterial.saveToDevice || false}
                  onChange={handleCheckboxChange}
                  name="saveToDevice"
                />
              }
              label="Save to Device"
            />
          </Grid>

          

          <Grid item xs={6}>
            <TextField
              label="Description"
              value={localMaterial.discription || ""}
              name="discription"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <Button variant="contained" component="label" fullWidth>
              Upload PDF
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                name="pdfFile"
              />
            </Button>
            {localMaterial.pdfFile ? (
              <span>{localMaterial.pdfFile.name}</span>
            ) : (
              <span>No file selected</span>
            )}
          </Grid>

          <Grid item xs={4}>
            <Button variant="contained" component="label" fullWidth>
              Upload Thumbnail
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                name="thumbnailFile"
              />
            </Button>
            {localMaterial.thumbnailFile ? (
              <span>{localMaterial.thumbnailFile.name}</span>
            ) : (
              <span>No file selected</span>
            )}
          </Grid>

          <Grid item xs={4}>
            <Button variant="contained" component="label" fullWidth>
              Upload Demo PDF
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                name="demoPdf"
              />
            </Button>
            {localMaterial.demoPdf ? (
              <span>{localMaterial.demoPdf.name}</span>
            ) : (
              <span>No file selected</span>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          disabled={!localMaterial.materialtype || !localMaterial.categoryName}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMaterial;
