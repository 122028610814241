// import React, { useState } from 'react';
// import {
//   Button,
//   TextField,
//   Select,
//   MenuItem,
//   InputLabel,
//   FormControl,
//   Grid,
//   Typography,
//   Paper,
//   Snackbar,
// } from '@mui/material';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import ImageIcon from '@mui/icons-material/Image';
// import MuiAlert from '@mui/material/Alert';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// const categoriesWithSubcategories = {
//   'Contemporary Fiction': ['Fiction', 'Non-Fiction', 'Both'],
//   'Self-Improvement': ['Honesty', 'Trust', 'Confidence', 'Discipline'],
//   Historical: ['Political', 'Diplomatic', 'Cultural', 'Intellectual'],
//   Marathi: ['Grammar', 'Literature', 'Drama', 'Poetry'],
// };

// export default function Add() {
//   const [ebookName, setEbookName] = useState('');
//   const [pdfFile, setPdfFile] = useState(null);
//   const [thumbnailFile, setThumbnailFile] = useState(null);
//   const [category, setCategory] = useState('');
//   const [subCategory, setSubCategory] = useState('');
//   const [saveToDevice, setSaveToDevice] = useState('no');
//   const [status, setStatus] = useState('free');
//   const [mrp, setMrp] = useState('');
//   const [price, setPrice] = useState('');
//   const [validity, setValidity] = useState('');
//   const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
//   const [categoryName, setCategoryName] = useState('');
//   const [categoryType, setCategoryType] = useState('');
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   const categories = Object.keys(categoriesWithSubcategories);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log({
//       ebookName,
//       pdfFile,
//       thumbnailFile,
//       category,
//       subCategory,
//       saveToDevice,
//       status,
//       mrp,
//       price,
//       validity,
//     });
//     resetForm();
//   };

//   const handleAddCategorySubmit = (e) => {
//     e.preventDefault();
//     // Logic for adding a new category
//     console.log('New category added:', categoryName, categoryType);
//     setOpenSnackbar(true);
//     setSnackbarMessage('Category added successfully!');
//     setShowAddCategoryForm(false);
//     resetAddCategoryForm();
//   };

//   const resetForm = () => {
//     setEbookName('');
//     setPdfFile(null);
//     setThumbnailFile(null);
//     setCategory('');
//     setSubCategory('');
//     setSaveToDevice('no');
//     setStatus('free');
//     setMrp('');
//     setPrice('');
//     setValidity('');
//   };

//   const resetAddCategoryForm = () => {
//     setCategoryName('');
//     setCategoryType('');
//   };

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//   };


//   // ebook,
//   // test- series
//   // old q papers


//   return (
//     <Paper elevation={3} style={{ padding: '20px', maxWidth: '80%', margin: 'auto' }}>
//       <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'green' }}>
//         Add material
//       </Typography>

//       <form onSubmit={handleSubmit}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Ebook Name/ Title"
//               variant="outlined"
//               fullWidth
//               value={ebookName}
//               onChange={(e) => setEbookName(e.target.value)}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth required>
//               <InputLabel>Select Category</InputLabel>
//               <Select
//                 value={category}
//                 onChange={(e) => {
//                   setCategory(e.target.value);
//                   setSubCategory(''); // Reset subcategory when category changes
//                 }}
//               >
//                 {categories.map((cat, index) => (
//                   <MenuItem key={index} value={cat}>{cat}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth required>
//               <InputLabel>Select Sub-Category</InputLabel>
//               <Select
//                 value={subCategory}
//                 onChange={(e) => setSubCategory(e.target.value)}
//                 disabled={!category} // Disable if no category is selected
//               >
//                 {category && categoriesWithSubcategories[category].map((subCat, index) => (
//                   <MenuItem key={index} value={subCat}>{subCat}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth required>
//               <InputLabel>Save in phone</InputLabel>
//               <Select
//                 value={saveToDevice}
//                 onChange={(e) => setSaveToDevice(e.target.value)}
//               >
//                 <MenuItem value="yes">Yes</MenuItem>
//                 <MenuItem value="no">No</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <FormControl fullWidth required>
//               <InputLabel>Select Status</InputLabel>
//               <Select
//                 value={status}
//                 onChange={(e) => setStatus(e.target.value)}
//               >
//                 <MenuItem value="free">Free</MenuItem>
//                 <MenuItem value="paid">Paid</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="MRP"
//               variant="outlined"
//               type="number"
//               fullWidth
//               value={mrp}
//               onChange={(e) => setMrp(e.target.value)}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Price"
//               variant="outlined"
//               type="number"
//               fullWidth
//               value={price}
//               onChange={(e) => setPrice(e.target.value)}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} sm={6}>
//             <TextField
//               label="Validity (in months)"
//               variant="outlined"
//               type="number"
//               fullWidth
//               value={validity}
//               onChange={(e) => setValidity(e.target.value)}
//               required
//             />
//           </Grid>

          
//         </Grid>

//         <Grid item xs={12} sm={6} mt={2}>
//             <Paper elevation={3} style={{ padding: '12px', width: '97%' }}>
//               <Grid container spacing={2} alignItems="center">
//                 <Grid item xs>
//                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="subtitle2" style={{ marginRight: '8px' }}>Upload PDF</Typography>
//                     <input
//                       type="file"
//                       accept=".pdf"
//                       onChange={(e) => setPdfFile(e.target.files[0])}
//                       required
//                       style={{ display: 'none' }}
//                       id="pdf-upload"
//                     />
//                     <label htmlFor="pdf-upload">
//                       <Button 
//                         variant="contained" 
//                         color='error' 
//                         component="span" 
//                         startIcon={<PictureAsPdfIcon />}
//                       >
//                         PDF
//                       </Button>
//                     </label>
//                   </div>
//                 </Grid>

//                 <Grid item xs>
//                   <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="subtitle2" style={{ marginRight: '8px' }}>Upload Thumbnail (png/jpg)</Typography>
//                     <input
//                       type="file"
//                       accept=".jpg,.png"
//                       onChange={(e) => setThumbnailFile(e.target.files[0])}
//                       required
//                       style={{ display: 'none' }}
//                       id="thumbnail-upload"
//                     />
//                     <label htmlFor="thumbnail-upload">
//                       <Button 
//                         variant="contained" 
//                         color='error' 
//                         component="span" 
//                         startIcon={<ImageIcon />}
//                       >
//                         cover
//                       </Button>
//                     </label>
//                   </div>
//                 </Grid>
//               </Grid>
//             </Paper>
//           </Grid>

//         <Grid container spacing={2} mt={2}>
//           <Grid item container spacing={2} gap={2} marginLeft={'0.5%'}>
//             <Button type="submit" variant="contained" color="primary">
//               Save
//             </Button>
//             <Button 
//               variant="contained" 
//               color="secondary" 
//               onClick={() => setShowAddCategoryForm(!showAddCategoryForm)}
//             >
//               Add Category
//             </Button>
//           </Grid>

//           {showAddCategoryForm && (
//             <Grid item xs={12} mt={2}>
//               <Paper elevation={3} style={{ padding: '20px' }}>
//                 <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'green' }}>
//                   Add Category
//                 </Typography>
//                 <form onSubmit={handleAddCategorySubmit}>
//                   <TextField
//                     label="Category Name"
//                     variant="outlined"
//                     fullWidth
//                     value={categoryName}
//                     onChange={(e) => setCategoryName(e.target.value)}
//                     required
//                   />
//                   <TextField
//                   sx={{ mt: 2 }}
//                     label="Category Type"
//                     variant="outlined"
//                     fullWidth
//                     value={categoryType}
//                     onChange={(e) => setCategoryType(e.target.value)}
//                     required
//                   />
//                   <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//                     Add Category
//                   </Button>
//                 </form>
//               </Paper>
//             </Grid>
//           )}
//         </Grid>
//       </form>

//       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
//         <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// }
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  Paper,
  Snackbar,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const categoriesWithSubcategories = {
  'Contemporary Fiction': ['Fiction', 'Non-Fiction', 'Both'],
  'Self-Improvement': ['Honesty', 'Trust', 'Confidence', 'Discipline'],
  Historical: ['Political', 'Diplomatic', 'Cultural', 'Intellectual'],
  Marathi: ['Grammar', 'Literature', 'Drama', 'Poetry'],
};

export default function Add() {
  const [ebookName, setEbookName] = useState('');
  // const [name, setName] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [thumbnail, setThumbnailFile] = useState(null);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [saveToDevice, setSaveToDevice] = useState('no');
  const [status, setStatus] = useState('free');
  const [mrp, setMrp] = useState('');
  const [price, setPrice] = useState('');
  const [validity, setValidity] = useState('');
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const categories = Object.keys(categoriesWithSubcategories);

  useEffect(() => {
    console.log('Category Name:', name);
    console.log('Category Type:', type);
    console.log('Thumbnail File:', thumbnail);
  }, [name, type, thumbnail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('ebookName', ebookName);
    formData.append('name', name);
    formData.append('pdfFile', pdfFile);
    formData.append('thumbnailFile', thumbnail);
    formData.append('category', category);
    formData.append('subCategory', subCategory);
    formData.append('saveToDevice', saveToDevice);
    formData.append('status', status);
    formData.append('mrp', mrp);
    formData.append('price', price);
    formData.append('validity', validity);

    try {
      const response = await axios.post('https://mahastudy.in:8443/addEbook', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Ebook added:', response.data);
      setOpenSnackbar(true);
      setSnackbarMessage('Ebook added successfully!');
      resetForm();
    } catch (error) {
      console.error('Error adding ebook:', error.response ? error.response.data : error.message);
      const errorMessage = error.response?.data?.message || 'Failed to add ebook!';
      setOpenSnackbar(true);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
    }
  };

  const resetForm = () => {
    setEbookName('');
    setPdfFile(null);
    setThumbnailFile(null);
    setCategory('');
    setSubCategory('');
    setSaveToDevice('no');
    setStatus('free');
    setMrp('');
    setPrice('');
    setValidity('');
    setName('');
  };

  const handleAddCategorySubmit = async (e) => {
    e.preventDefault();
    
    const newCategoryData = {
      name: setName,
      type: setCategory,
      thumbnail // Ensure this is handled properly, or remove if not needed
    };

    console.log('New category data being sent:', newCategoryData); // Log the data

    try {
      const response = await axios.post('https://mahastudy.in:8443/addCategory', newCategoryData);
      console.log('New category added:', response.data);
      setOpenSnackbar(true);
      setSnackbarMessage('Category added successfully!');
      setSnackbarSeverity('success');
      resetAddCategoryForm();
    } catch (error) {
      console.error('Error adding category:', error.response ? error.response.data : error.message);
      const errorMessage = error.response?.data?.message || 'Failed to add category!';
      setOpenSnackbar(true);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
    }
  };

  const resetAddCategoryForm = () => {
    setName('');
    setType('');
    setThumbnailFile(null); // Reset thumbnail if required
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', maxWidth: '80%', margin: 'auto' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'green' }}>
        Add Material
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Ebook Name/Title"
              variant="outlined"
              fullWidth
              value={ebookName}
              onChange={(e) => setEbookName(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setSubCategory(''); // Reset subcategory when category changes
                }}
              >
                {categories.map((cat, index) => (
                  <MenuItem key={index} value={cat}>
                    {cat}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Sub-Category</InputLabel>
              <Select
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
                disabled={!category} // Disable if no category is selected
              >
                {category &&
                  categoriesWithSubcategories[category].map((subCat, index) => (
                    <MenuItem key={index} value={subCat}>
                      {subCat}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Save on Phone</InputLabel>
              <Select
                value={saveToDevice}
                onChange={(e) => setSaveToDevice(e.target.value)}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Select Status</InputLabel>
              <Select value={status} onChange={(e) => setStatus(e.target.value)}>
                <MenuItem value="free">Free</MenuItem>
                <MenuItem value="paid">Paid</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="MRP"
              variant="outlined"
              type="number"
              fullWidth
              value={mrp}
              onChange={(e) => setMrp(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Price"
              variant="outlined"
              type="number"
              fullWidth
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="Validity (in months)"
              variant="outlined"
              type="number"
              fullWidth
              value={validity}
              onChange={(e) => setValidity(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <Paper elevation={1} style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle2" style={{ marginRight: '8px' }}>Upload PDF</Typography>
                    <input
                      type="file"
                      accept=".pdf"
                      onChange={(e) => setPdfFile(e.target.files[0])}
                      required
                      style={{ display: 'none' }}
                      id="pdf-upload"
                    />
                    <label htmlFor="pdf-upload">
                      <Button
                        variant="contained"
                        color="error"
                        component="span"
                        startIcon={<PictureAsPdfIcon />}
                      >
                        PDF
                      </Button>
                    </label>
                  </div>
                </Grid>

                <Grid item xs>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle2" style={{ marginRight: '8px' }}>Upload Thumbnail (png/jpg)</Typography>
                    <input
                      type="file"
                      accept=".jpg,.png"
                      onChange={(e) => setThumbnailFile(e.target.files[0])}
                      required
                      style={{ display: 'none' }}
                      id="thumbnail-upload"
                    />
                    <label htmlFor="thumbnail-upload">
                      <Button
                        variant="contained"
                        color="error"
                        component="span"
                        startIcon={<ImageIcon />}
                      >
                        Cover
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid container spacing={2} mt={2}>
            <Grid item container spacing={2} gap={2} marginLeft={'0.5%'}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowAddCategoryForm(!showAddCategoryForm)}
              >
                Add Category
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {showAddCategoryForm && (
        <form onSubmit={handleAddCategorySubmit}>
          <Typography variant="h5" mt={4} mb={2}>
            Add New Category
          </Typography>

          <TextField
            label="Category Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            style={{ marginTop: '16px' }}
          />

          <TextField
            label="Category Type"
            variant="outlined"
            fullWidth
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
            style={{ marginTop: '16px' }}
          />

          <Grid item xs>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Typography variant="subtitle2" style={{ marginRight: '8px' }}>Upload Thumbnail (png/jpg)</Typography>
              <input
                type="file"
                accept=".jpg,.png"
                onChange={(e) => setThumbnailFile(e.target.files[0])}
                style={{ display: 'none' }}
                id="thumbnail-upload-category"
              />
              <label htmlFor="thumbnail-upload-category">
                <Button
                  variant="contained"
                  color="error"
                  component="span"
                  startIcon={<ImageIcon />}
                >
                  Cover
                </Button>
              </label>
            </div>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '16px' }}
          >
            Save Category
          </Button>
        </form>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
}
