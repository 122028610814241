// import React, { useState } from "react";
// import {
//   Button,
//   TextField,
//   Typography,
//   Container,
//   Card,
//   CardContent,
//   CardActions,
//   Grid,
// } from "@mui/material";
// import Stack from "@mui/material/Stack";
// import { useNavigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; // Import the toastify styles
// const LoginPage = () => {
//   const navigate = useNavigate(); // Initialize the hook
//   const [adminEmail, setAdminEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");

//   const handleLogin = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch("https://mahastudy.in:8443/admin/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ adminEmail, password }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Login failed!");
//       }

//       const data = await response.json();
//      toast.success("Login successful!", {
//         position: "top-right",
//         autoClose: 1000,
//       });
//       setTimeout(() => {
//         navigate("/ebooklayout/ebookdashboard");
//       }, 1000);
//     } catch (error) {
//       // Show error toast on failed login
//       toast.error(error.message, {
//         position: "top-right",
//       });

//       setErrorMessage(error.message); // Update state with error message
//     }
//   };

//   const handleCreateAccount = () => {
//     navigate("/ebooksignup"); // Navigate to the sign-up page
//   };

//   return (
//     <>
//       <Typography fontSize="70px" textAlign="center" color="rgb(52, 152, 219)">
//         MAHASTUDY
//       </Typography>
//       <div
//         style={{
//           minHeight: "60vh",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <ToastContainer />
//         <Container
//           component="main"
//           maxWidth="md" // Change to 'md' to allow more space for both form and GIF
//           style={{
//             padding: "16px",
//             borderRadius: "8px",
//           }}
//         >
//           <Grid container spacing={2} alignItems="center">
//             {/* Left side with the GIF */}
//             <Grid item xs={12} sm={6}>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   marginRight: "70px",
//                 }}
//               >
//                 <img
//                   src="https://cdn.dribbble.com/users/218750/screenshots/1793485/media/c2747cb160664ad7825abdaceda5d805.gif"
//                   alt="GIF"
//                   style={{ maxWidth: "500px", height: "330px" }}
//                 />
//               </div>
//             </Grid>

//             {/* Right side with the login form */}
//             <Grid item xs={12} sm={6} className="textField-root">
//               <Card
//                 elevation={8}
//                 style={{ marginRight: 40, backgroundColor: "#f5f5f5" }}
//               >
//                 <CardContent>
//                   <Typography
//                     component="h1"
//                     variant="h5"
//                     align="center"
                  
//                     style={{
//                       marginBottom: "16px",
//                       fontWeight: "bold",
//                       color: "rgb(52, 152, 219)",
//                     }}
//                   >
//                     Log In
//                   </Typography>
//                   <form noValidate onSubmit={handleLogin}>
//                     <Stack spacing={2} marginTop={2}>
//                       <TextField
//                         required
//                         fullWidth
//                         label="Email"
//                         variant="outlined"
//                         autoFocus
//                         value={adminEmail}
//                         onChange={(e) => setAdminEmail(e.target.value)} // Update the adminEmail state
//                       />
//                       <TextField
//                         required
//                         fullWidth
//                         label="Password"
//                         type="password"
//                         variant="outlined"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)} // Update the password state
//                       />
//                       {errorMessage && (
//                         <Typography color="error" variant="body2">
//                           {errorMessage}
//                         </Typography>
//                       )}
//                       <Button
//                         type="submit"
//                         fullWidth
//                         variant="contained"
//                         sx={{ backgroundColor: "rgb(52, 152, 219)" }}
//                       >
//                         Login
//                       </Button>
//                     </Stack>
//                   </form>
//                 </CardContent>
//                 <CardActions>
//                   <Grid
//                     container
//                     justifyContent="flex-end"
//                     style={{ marginTop: "16px" }}
//                   >
//                     <Grid item>
//                       <Button onClick={handleCreateAccount} color="primary">
//                         Create Account
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </CardActions>
//               </Card>
//             </Grid>
//           </Grid>
//         </Container>
//       </div>
//     </>
//   );
// };

// export default LoginPage;
import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Card,
  CardContent,
  CardActions,
  Grid,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  const navigate = useNavigate();
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://mahastudy.in:8443/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ adminEmail, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed!");
      }

      toast.success("Login successful!", { position: "top-right", autoClose: 1000 });
      setTimeout(() => {
        navigate("/ebooklayout/ebookdashboard");
      }, 1000);
    } catch (error) {
      toast.error(error.message, { position: "top-right" });
    }
  };

  return (
    <>
      <Typography fontSize="70px" textAlign="center" color="rgb(52, 152, 219)">
        MAHASTUDY
      </Typography>
      <Container
        component="main"
        maxWidth="md"
        style={{
          padding: "16px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <img
              src="https://cdn.dribbble.com/users/218750/screenshots/1793485/media/c2747cb160664ad7825abdaceda5d805.gif"
              alt="Login Illustration"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card elevation={8} style={{ backgroundColor: "#f5f5f5" }}>
              <CardContent>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  style={{ marginBottom: "16px", fontWeight: "bold", color: "rgb(52, 152, 219)" }}
                >
                  Log In
                </Typography>
                <form noValidate onSubmit={handleLogin}>
                  <Stack spacing={2}>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      variant="outlined"
                      value={adminEmail}
                      onChange={(e) => setAdminEmail(e.target.value)}
                    />
                    <TextField
                      required
                      fullWidth
                      label="Password"
                      type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ backgroundColor: "rgb(52, 152, 219)" }}
                    >
                      Login
                    </Button>
                  </Stack>
                </form>
              </CardContent>
              <CardActions>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button onClick={() => navigate("/ebooksignup")} color="primary">
                      Create Account
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default LoginPage;
