import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchSubcategories,
  addSubCategory,
  updateSubCategory,
  deleteSubCategory,
} from "./API/AllApi";

const SubcategoryList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { materialTypeId, materialTypeName } = state || {};
  const { categoryId, categoryName } = location.state || {};
  
  const [subcategories, setSubcategories] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [currentSubcategoryId, setCurrentSubcategoryId] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const loadSubcategories = async () => {
      if (categoryId && categoryName) {
        try {
          const response = await fetchSubcategories(categoryId, categoryName);
          setSubcategories(response.data);
        } catch (error) {
          Swal.fire("Error", "Failed to fetch subcategories", "error");
          console.error("Error fetching subcategories:", error);
        }
      } else {
        Swal.fire("Warning", "Category ID or Name is undefined!", "warning");
      }
    };

    loadSubcategories();
  }, [categoryId, categoryName]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewSubCategoryName("");
    setEditMode(false);
    setCurrentSubcategoryId(null);
  };

  const handleSaveSubCategory = async () => {
    try {
      if (editMode) {
        await updateSubCategory(currentSubcategoryId, newSubCategoryName);
        setSubcategories((prev) =>
          prev.map((sub) =>
            sub.id === currentSubcategoryId
              ? { ...sub, subcategoryName: newSubCategoryName }
              : sub
          )
        );
        Swal.fire("Updated!", "Subcategory updated successfully", "success");
      } else {
        const response = await addSubCategory(newSubCategoryName, categoryId);
        setSubcategories((prev) => [
          ...prev,
          { id: response.data.id, subcategoryName: newSubCategoryName },
        ]);
        Swal.fire("Added!", "Subcategory added successfully", "success");
      }
      handleDialogClose();
    } catch (error) {
      Swal.fire("Error", "Failed to save subcategory", "error");
      console.error("Error saving subcategory:", error);
    }
  };

  const handleEditSubcategory = (subcategory) => {
    setEditMode(true);
    setCurrentSubcategoryId(subcategory.id);
    setNewSubCategoryName(subcategory.subcategoryName);
    setDialogOpen(true);
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteSubCategory(subcategoryId);
          setSubcategories((prev) =>
            prev.filter((sub) => sub.id !== subcategoryId)
          );
          Swal.fire("Deleted!", "Subcategory has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error", "Failed to delete subcategory", "error");
          console.error("Error deleting subcategory:", error);
        }
      }
    });
  };

  // Filter subcategories based on search input
  const filteredSubcategories = subcategories.filter((subcategory) =>
    subcategory.subcategoryName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <h2 align="center">Subcategories of {categoryName}</h2>
      <Grid container spacing={2} alignItems="center" className="textField-root">
      <Grid item xs={12} md={2.4}>
          <Typography variant="h6">
            Subcategory Count: {filteredSubcategories.length}
          </Typography>
        </Grid>
      <Grid item xs={12} md={2.4}>
      <TextField
        label="Search Subcategories"
        variant="outlined"
        margin="normal"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
        </Grid>
        <Grid item xs={12} md={2.4}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDialogOpen}
      >
        Add Subcategory
      </Button>
      </Grid>
      <Grid item xs={12} md={2.4}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => navigate(-1)}
        style={{ marginLeft: "10px" }}
      >
        Back to Categories
      </Button>
      </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{editMode ? "Edit Subcategory" : "Add New Subcategory"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Subcategory Name"
            fullWidth
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveSubCategory} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        {filteredSubcategories.length === 0 ? (
          <p>No subcategories available.</p>
        ) : (
          <Table className="table-root">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Subcategory Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubcategories.map((subcategory) => (
                <TableRow key={subcategory.id}>
                  <TableCell>{subcategory.id}</TableCell>
                  <TableCell>{subcategory.subcategoryName}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditSubcategory(subcategory)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteSubcategory(subcategory.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default SubcategoryList;
