// // import React from 'react';
// // import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// // import { Box, CssBaseline, Button } from '@mui/material';
// // import Navbar from './Navbar';
// // import Sidebar from './Sidebar';

// // const Layout = () => {
// //   const location = useLocation();
// //   const navigate = useNavigate();

// //   const isRootPath = location.pathname === '/';

// //   return (
// //     <Box sx={{ display: 'flex' }}>
// //       <CssBaseline />
// //       <Navbar />
// //       {!isRootPath && <Sidebar />}
// //       <Box
// //         component="main"
// //         sx={{
// //           flexGrow: 1,
// //           p: 3,
// //           background: isRootPath ? 'url(/path-to-background-image.jpg)' : 'none',
// //           backgroundSize: 'cover',
// //           height: '100vh',
// //           display: 'flex',
// //           flexDirection: 'column',
// //           alignItems: 'center',
// //           justifyContent: 'center',
// //         }}
// //       >
// //         {isRootPath && (
// //           <Button
// //             variant="contained"
// //             sx={{ mt: 3, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' } }}
// //             onClick={() => navigate('/dashboard')}
// //           >
// //             Go to the Dashboard
// //           </Button>
// //         )}
// //         <Outlet />
// //       </Box>
// //     </Box>
// //   );
// // };

// // export default Layout;
// import React from 'react';
// import { Outlet } from 'react-router-dom';
// import Navbar from '../components/Navbar';
// import Sidebar from '../components/Sidebar';
// import { Box, Typography } from '@mui/material';

// const Layout = () => {
//   return (
//     <Box sx={{ display: 'flex', minHeight: '100vh' }}>
//       <Navbar />
//       <Sidebar />
      
//       {/* Main content area */}
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           p: 3, // Padding around the content to keep space from the Sidebar and Navbar
//           ml: { sm: '200px' }, // Keep margin-left for the width of the sidebar
//            mt: '64px', // Margin-top to keep space from Navbar
//          // backgroundColor: 'background.default',
//         }}
//       >
//         <Outlet /> {/* This will render the nested routes */}
        
//         <Typography variant="body2" align="center" sx={{ marginTop: 5 }}>
//           PJSOFTTECH Pvt. Ltd.{' '}
//           <Typography component="span" variant="body2" sx={{ color: 'red' }}>
//             © All Rights Reserved
//           </Typography>
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default Layout;

import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Box, Typography } from "@mui/material";

const Layout = () => {
  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Navbar />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          ml: { sm: "200px" },
          mt: "64px",
        }}
      >
        <Outlet />
        <Typography variant="body2" align="center" sx={{ marginTop: 5 }}>
          PJSOFTTECH Pvt. Ltd.{" "}
          <Typography component="span" variant="body2" sx={{ color: "red" }}>
            © All Rights Reserved
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Layout;
