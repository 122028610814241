import React, { useState } from 'react';
import { 
    Box, 
    Button, 
    Container, 
    CssBaseline, 
    Grid, 
    TextField, 
    Typography, 
    Link
} from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for toast notifications
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Signup = () => {
    const [formData, setFormData] = useState({
        adminUserName: '',
        adminEmail: '',
        password: '',
        confirmPassword: '',
        adminContact: '',
    });

    const navigate = useNavigate(); // Initialize useNavigate for navigation

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Make the API call to create the account
        try {
            const response = await axios.post('https://mahastudy.in:8443/admin/createAccount', {
                adminUserName: formData.adminUserName,
                adminEmail: formData.adminEmail,
                password: formData.password,
                confirmPassword: formData.confirmPassword,
                adminContact: formData.adminContact,
            });

            if (response.status === 200) {
                // Show success notification
                toast.success('Account created successfully! Redirecting to login...', {
                    position: "top-right", // Correct property for position
                    autoClose: 3000, // Close after 3 seconds
                });

                // Navigate to the /admin route after a short delay
                setTimeout(() => {
                    navigate('/admin');
                }, 3000); // 3-second delay to allow the toast to be visible
            }
        } catch (error) {
            console.error('Error creating account', error);
            // Show error notification
            toast.error('Error creating account. Please try again.', {
                position: "top-right", // Correct property for position
            });
        }
    };

    return (
        <Container component="main">
            <CssBaseline />
            <ToastContainer /> {/* Toast container for displaying notifications */}
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                {/* Left side with the image */}
                <Grid item xs={8} sm={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: 40,
                            height: 400,
                            width: 500,
                        }}
                    >
                        <img 
                            src="https://cdn.dribbble.com/users/218750/screenshots/1793485/media/c2747cb160664ad7825abdaceda5d805.gif"
                            alt="GIF" 
                            style={{ maxWidth: '100%', height: 'auto' }} 
                        />
                    </Box>
                </Grid>
                
                {/* Right side with the form */}
                <Grid item xs={8} sm={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 3,
                            borderRadius: 2,
                            boxShadow: 3,
                            backgroundColor: '#f5f5f5',
                            marginTop: 10,
                        }}
                    >
                        <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', color: 'green' }}>
                            EBook
                        </Typography>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                            Sign up
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="adminUserName"
                                        label="Full Name"
                                        name="adminUserName"
                                        autoComplete="name"
                                        value={formData.adminUserName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="adminEmail"
                                        label="Email Address"
                                        name="adminEmail"
                                        autoComplete="email"
                                        value={formData.adminEmail}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="adminContact"
                                        label="Contact Number"
                                        name="adminContact"
                                        autoComplete="tel"
                                        value={formData.adminContact}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type="password"
                                        id="confirmPassword"
                                        autoComplete="new-password"
                                        value={formData.confirmPassword}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' } }}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="#" variant="body2" sx={{ color: 'green' }}>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Signup;
