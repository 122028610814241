import React, { useState } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const initialData = [
  { name: 'Contemporary Fiction', value: 400 },
  { name: 'Self-Improvement', value: 300 },
  { name: 'Historical', value: 300 },
  { name: 'Technology', value: 200 },
];

const initialPurchases = [
  { name: 'Purchase #1', value: 15 },
  { name: 'Purchase #2', value: 25 },
  { name: 'Purchase #3', value: 20 },
  { name: 'Purchase #4', value: 30 },
];

const COLORS = [
  '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
  '#FF6361', '#58508D', '#FFA600', '#BC5090',
  '#003F5C', '#7A5195', '#EF5675', '#FF764A',
  '#2F4B7C', '#A05195', '#D45087', '#F95D6A'
];

const dataMapping = {
  'January': {
    '2023': {
      data: [
        { name: 'Contemporary Fiction', value: 350 },
        { name: 'Self-Improvement', value: 280 },
        { name: 'Historical', value: 320 },
        { name: 'Marathi', value: 300 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 20 },
        { name: 'Purchase #2', value: 30 },
        { name: 'Purchase #3', value: 25 },
        { name: 'Purchase #4', value: 35 },
      ],
    },
    '2024': {
      data: [
        { name: 'Contemporary Fiction', value: 400 },
        { name: 'Self-Improvement', value: 100 },
        { name: 'Historical', value: 327 },
        { name: 'Marathi', value: 500 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 17 },
        { name: 'Purchase #2', value: 10 },
        { name: 'Purchase #3', value: 20 },
        { name: 'Purchase #4', value: 8 },
      ],
    },
    '2025': {
      data: [
        { name: 'Contemporary Fiction', value: 450 },
        { name: 'Self-Improvement', value: 150 },
        { name: 'Historical', value: 357 },
        { name: 'Marathi', value: 550 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 19 },
        { name: 'Purchase #2', value: 23 },
        { name: 'Purchase #3', value: 33 },
        { name: 'Purchase #4', value: 10 },
      ],
    },
    '2026': {
      data: [
        { name: 'Contemporary Fiction', value: 350 },
        { name: 'Self-Improvement', value: 550 },
        { name: 'Historical', value: 320 },
        { name: 'Marathi', value: 350 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 12 },
        { name: 'Purchase #2', value: 29 },
        { name: 'Purchase #3', value: 44 },
        { name: 'Purchase #4', value: 10 },
      ],
    },
    // Add more years here as needed
  },
  // Add more months here as needed
  'February': {
    '2023': {
      data: [
        { name: 'Contemporary Fiction', value: 300 },
        { name: 'Self-Improvement', value: 220 },
        { name: 'Historical', value: 180 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 19 },
        { name: 'Purchase #2', value: 27 },
        { name: 'Purchase #3', value: 35 },
      ],
    },
    '2024': {
      data: [
        { name: 'Contemporary Fiction', value: 310 },
        { name: 'Self-Improvement', value: 230 },
        { name: 'Historical', value: 140 },
        { name: 'Marathi', value: 110 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 20 },
        { name: 'Purchase #2', value: 30 },
        { name: 'Purchase #3', value: 25 },
        { name: 'Purchase #3', value: 45 },
      ],
    },
    '2025': {
      data: [
        { name: 'Contemporary Fiction', value: 210 },
        { name: 'Self-Improvement', value: 230 },
        { name: 'Historical', value:2140 },
        { name: 'Marathi', value: 160 },
      ],
      purchases: [
        { name: 'Purchase #1', value: 25 },
        { name: 'Purchase #2', value: 35 },
        { name: 'Purchase #3', value: 20 },
        { name: 'Purchase #3', value: 40 },
      ],
    },
    // Add more years here as needed
  },
  // Continue adding for all months and years as needed
};



export default function Dashboard() {
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [data, setData] = useState(initialData);
  const [purchases, setPurchases] = useState(initialPurchases);
  const [totalEBooks, setTotalEBooks] = useState(0);
  const [categories, setCategories] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    updateData(event.target.value, year);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    updateData(month, event.target.value);
  };

   const updateData = (selectedMonth, selectedYear) => {
    const monthData = dataMapping[selectedMonth]?.[selectedYear];
    if (monthData) {
      setData(monthData.data);
      setPurchases(monthData.purchases);

      const totalEBooks = monthData.data.reduce((sum, entry) => sum + entry.value, 0);
      const categories = monthData.data.length;
      const totalSales = monthData.purchases.reduce((sum, entry) => sum + entry.value, 0);
      const revenue = totalSales * 15; // Assuming each sale contributes $15 to revenue.

      setTotalEBooks(totalEBooks);
      setCategories(categories);
      setTotalSales(totalSales);
      setRevenue(revenue);
    } else {
      setData(initialData);
      setPurchases(initialPurchases);
      setTotalEBooks(initialData.reduce((sum, entry) => sum + entry.value, 0));
      setCategories(initialData.length);
      setTotalSales(initialPurchases.reduce((sum, entry) => sum + entry.value, 0));
      setRevenue(initialPurchases.reduce((sum, entry) => sum + entry.value, 0) * 15);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
{/* 
<Paper elevation={3} style={{ padding: '20px', maxWidth: '100%', margin: 'auto' }}>  */}
  <Grid container alignItems="center" spacing={1}>
    {/* <Grid item xs={12} sm={4}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'green' }}>
        EBook Dashboard
      </Typography>
    </Grid> */}
    <Grid item xs={12} sm={8}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl variant="outlined" size="small" required fullWidth>
            <InputLabel>Month</InputLabel>
            <Select
              value={month}
              onChange={handleMonthChange}
              label="Month"
            >
              <MenuItem value="January">January</MenuItem>
              <MenuItem value="February">February</MenuItem>
              <MenuItem value="March">March</MenuItem>
              <MenuItem value="April">April</MenuItem>
              <MenuItem value="May">May</MenuItem>
              <MenuItem value="June">June</MenuItem>
              <MenuItem value="July">July</MenuItem>
              <MenuItem value="August">August</MenuItem>
              <MenuItem value="September">September</MenuItem>
              <MenuItem value="October">October</MenuItem>
              <MenuItem value="November">November</MenuItem>
              <MenuItem value="December">December</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" size="small" required fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              onChange={handleYearChange}
              label="Year"
            >
              <MenuItem value="2019">2019</MenuItem>
              <MenuItem value="2020">2020</MenuItem>
              <MenuItem value="2021">2021</MenuItem>
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
              <MenuItem value="2027">2027</MenuItem>
              <MenuItem value="2028">2028</MenuItem>
              <MenuItem value="2029">2029</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
{/* </Paper> */}



<Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: '20px', bgcolor: '#2196f3', color: '#fff' }}>
            <Typography variant="h6">Total EBooks</Typography>
            <Typography variant="h4">{totalEBooks}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: '20px', bgcolor: '#f44336', color: '#fff' }}>
            <Typography variant="h6">Categories</Typography>
            <Typography variant="h4">{categories}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: '20px', bgcolor: '#4caf50', color: '#fff' }}>
            <Typography variant="h6">Total Sales</Typography>
            <Typography variant="h4">{totalSales}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ padding: '20px', bgcolor: '#ff9800', color: '#fff' }}>
            <Typography variant="h6">Revenue</Typography>
            <Typography variant="h4">${revenue}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <Typography variant="h6" align="center" gutterBottom>
              EBooks by Category
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: '20px' }}>
            <Typography variant="h6" align="center" gutterBottom>
              Recent Purchases
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={purchases}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#0088FE" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}


