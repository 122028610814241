
import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';

// Define the theme
const theme = createTheme();

export default function Sidebar({ mobileOpen, handleDrawerToggle }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedItem, setSelectedItem] = useState('dashboard'); // State to manage selected item

  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/ebookdashboard"
            onClick={() => setSelectedItem('ebookdashboard')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'ebookdashboard' ? '#1F618D' : 'transparent', // Highlight selected item
            }}
          >
            <ListItemIcon><DashboardRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/addmatrialdata"
            onClick={() => setSelectedItem('addmatrialdata')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'addmatrialdata' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><DynamicFormIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Add Material" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/AddCategory"
            onClick={() => setSelectedItem('addCategory')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'addCategory' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><AddIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Category" />
          </ListItemButton>
        </ListItem> */}

        {/* <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/category"
            onClick={() => setSelectedItem('categoryList')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'categoryList' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><ViewListIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Category" />
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/AddMaterial"
            onClick={() => setSelectedItem('addMaterialType')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'addMaterialType' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><AddIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Add Material Type" />
          </ListItemButton>
        </ListItem>

       

      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/report"
            onClick={() => setSelectedItem('materialList')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'materialList' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><AssessmentRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Material List" />
          </ListItemButton>
        </ListItem>


        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/ebooklayout/SalesList"
            onClick={() => setSelectedItem('SalesList')}
            sx={{
              color: 'white', // Set text color to white
              backgroundColor: selectedItem === 'SalesList' ? '#1F618D' : 'transparent',
            }}
          >
            <ListItemIcon><ViewListIcon sx={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="SalesList" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: 250,
              top: '64px',
              height: 'calc(100% - 64px)',
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          open
          sx={{
            '& .MuiDrawer-paper': {
              width: 250,
              top: '64px',
              height: 'calc(100% - 64px)',
              backgroundColor: '#3498DB', // Drawer background color
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
}

