// import * as React from 'react';
// import { useState } from 'react';
// import { createTheme, ThemeProvider, styled, alpha } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';
// import MenuIcon from '@mui/icons-material/Menu';
// import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button'; // Import Button
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
// import Sidebar from './Sidebar'; // Import your Sidebar component

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#2196f3', // Blue color for primary theme
//     },
//     secondary: {
//       main: '#ffffff', // White for contrast
//     },
   
//   },
//   components: {
//     MuiAppBar: {
//       styleOverrides: {
//         root: {
//           background: 'linear-gradient(to right, #d4e157, #9ccc65)', // Light yellow-green to darker yellow-green gradient
//           opacity: 1, // Set to 1 to make it non-transparent
//         },
//       },
//     },
//   },
// });



// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));

// export default function Navbar({ handleDrawerToggle }) {
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const location = useLocation(); // Get the current location
//   const navigate = useNavigate(); // Initialize useNavigate

//   const handleSignUpClick = () => {
//     navigate('/Layout/ebooksignup'); // Navigate to the signup page
//   };

//   return (
//     <ThemeProvider theme={theme}>
//     <Box sx={{ display: 'flex' }}>
//       <AppBar position="fixed">
//         <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
//           {/* Left Side: Menu icon (if mobile) and App name */}
//           <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             {isMobile && location.pathname === '/' && ( // Show menu icon only on Home page
//               <IconButton
//                 color="inherit"
//                 aria-label="open drawer"
//                 edge="start"
//                 onClick={handleDrawerToggle}
//                 sx={{ mr: 2 }}
//               >
//                 <MenuIcon />
//               </IconButton>
//             )}
//             <Typography
//               variant="h5"
//               textAlign="center"
//               sx={{
//                 color: 'green',
//                 fontFamily: 'Poppins, Helvetica, sans-serif', // Updated font family
//                 fontWeight: 'bold',
//               }}
//             >
//               EBook Application
//             </Typography>
//           </Box>

//           {/* Right Side: Search bar and Sign Up button */}
//           {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Search>
//               <SearchIconWrapper>
//                 <SearchIcon />
//               </SearchIconWrapper>
//               <StyledInputBase
//                 placeholder="Search…"
//                 inputProps={{ 'aria-label': 'search' }}
//               />
//             </Search>
//             <Button 
//               variant="contained" 
//               color="primary" 
//               onClick={handleSignUpClick} 
//               sx={{ marginLeft: 2 }}
//             >
//               Sign Up
//             </Button>
//           </Box> */}
//         </Toolbar>
//       </AppBar>

//       {/* Main content section with spacing from Navbar */}
//       <Box
//         component="main"
//         sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3}}
//       >
//         {/* Add your main content here */}
//       </Box>
//     </Box>
//   </ThemeProvider>
// );
// };


import * as React from 'react';
import { useState } from 'react';
import { createTheme, ThemeProvider, styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar'; // Import Avatar
import Menu from '@mui/material/Menu'; // Import Menu
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem
import Dialog from '@mui/material/Dialog'; // Import Dialog
import DialogActions from '@mui/material/DialogActions'; // Import DialogActions
import DialogContent from '@mui/material/DialogContent'; // Import DialogContent
import DialogContentText from '@mui/material/DialogContentText'; // Import DialogContentText
import DialogTitle from '@mui/material/DialogTitle'; // Import DialogTitle
import Button from '@mui/material/Button'; // Import Button
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Blue color for primary theme
    },
    secondary: {
      main: '#ffffff', // White for contrast
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#00649F', // Light yellow-green to darker yellow-green gradient
          opacity: 1, // Set to 1 to make it non-transparent
        },
      },
    },
  },
});

export default function Navbar({ handleDrawerToggle }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor for profile menu
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false); // State for logout confirmation dialog
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // Initialize useNavigate

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    setOpenLogoutDialog(true); // Open the logout confirmation dialog
  };

  const handleLogoutConfirm = () => {
    setOpenLogoutDialog(false); // Close the dialog
    navigate('/admin'); // Navigate to admin page
  };

  const handleDialogClose = () => {
    setOpenLogoutDialog(false); // Close the dialog without logout
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Left Side: Menu icon (if mobile) and App name */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isMobile && location.pathname === '/' && ( // Show menu icon only on Home page
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h5"
                textAlign="center"
                sx={{
                  color: 'white',
                 
                
                }}
              >
                MAHASTUDY
              </Typography>
            </Box>

            {/* Right Side: Profile icon */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleProfileClick} sx={{ p: 0 }}>
                <Avatar alt="Profile Picture" src="/static/images/avatar/1.jpg" />
              </IconButton>

              {/* Profile Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: '45px' }}
              >
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Logout Confirmation Dialog */}
        <Dialog
          open={openLogoutDialog}
          onClose={handleDialogClose}
          aria-labelledby="logout-dialog-title"
          aria-describedby="logout-dialog-description"
        >
          <DialogTitle id="logout-dialog-title">{"Confirm Logout"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="logout-dialog-description">
              Are you sure you want to logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              No
            </Button>
            <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {/* Main content section with spacing from Navbar */}
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          {/* Add your main content here */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
